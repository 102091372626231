import React from 'react';
import Map from './Map';
import Layout from './Layout';


function Home(props) {


    return (
        <div>
            <Layout loggedIn={props.loggedIn} signOut={props.signOut} setSite={props.setSite}/>
        </div>
    );
}

export default Home;