import React, { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { ProjectContext } from "../../context/projects";
import { Container, Row, Col } from 'react-bootstrap';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Typography, Box } from '@mui/material';

import AoC from '../../modal/AoC';
import Video360 from '../../modal/Video360';
import MapImage from '../../modal/MapImage';

const DEFAULT_VIDEO = process.env.PUBLIC_URL + '/video/River_Bass_Loop.mp4';


function Project() {
    const [aocShow, setAocShow] = useState(false);
    const [video360Show, setVideo360Show] = useState(false);
    const [mapImageShow, setMapImageShow] = useState(false);

    const { projectID } = useParams();
    const { projects } = useContext(ProjectContext);
    const project = projects.find((project) => {
        return project.id === projectID;
    });
    if (!project) {
        return <h3>Loading...</h3>;
    }


    return (
        <div>
            <Stack space={2} >
                <h3 style={tileStyle}>{project.title}</h3>

                <video autoPlay loop muted
                    src={project.videoAccessURL} onError={(e) => { e.target.onerror = null; e.target.src = DEFAULT_VIDEO }}
                    type="video/mp4" alt={project.title} />

                <Typography>
                    {project.description}
                </Typography>


                <Box sx={{
                    display: { xs: 'none', sm: 'block' },
                    paddingTop: 2
                }}>
                    <Row>
                        <Col>
                            <div className="button__holderAoc">
                                <button className="aoc" onClick={() => { setAocShow(true); console.log('aoc') }}></button>
                            </div>
                            <AoC video={project.aocVideo} show={aocShow} setShow={setAocShow} />
                        </Col>
                        <Col>
                            <div className="button__holderPano">
                                <button className="pano" onClick={() => setVideo360Show(true)}></button>
                            </div>
                            <Video360 video={project.video360} show={video360Show} setShow={setVideo360Show} />
                        </Col>
                        <Col>
                            <div className="button__holderSubscribe">
                                <form action={"/projects/" + project.id}>
                                    <button className="sub" type="submit"></button>
                                </form>
                            </div>
                        </Col>
                        {project.map !== '' ? (
                            <Col>

                                <div className="button__holderCultural">
                                    <button className="cul" onClick={() => setMapImageShow(true)}></button>

                                </div>
                                <MapImage map={project.map} show={mapImageShow} setShow={setMapImageShow} />
                            </Col>)
                            : (<div></div>)}

                    </Row>


                </Box>


            </Stack>

        </div>
    );
}

const tileStyle = {
    fontWeight: "bold"
}

const sidebarButtons = {
    position: "absolute",
    bottom: "6em",
    width: "60%"
}

export default Project;