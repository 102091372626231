import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MobileBottomNav from '../mobile/MobileBottomNav';
import MobileDrawerContent from '../mobile/MobileDrawerContent';
import DescriptionModal from './DescriptionModal';

import React, { useRef, useEffect, useState, useContext } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import Sidebar from './Sidebar/Sidebar';
import ProjectSlider from '../projects/ProjectSlider';
import VSLogo from './VSLogo';
import Timeline from '../timeline/Timeline';
import Footer from '../footer/Footer';
import { useNavigate } from "react-router-dom";

import { ProjectContext } from "../context/projects";
import { FormGroup } from '@material-ui/core';
import { Stack, Switch } from '@mui/material';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
    'pk.eyJ1IjoiZ3VuZXJpYm9pIiwiYSI6ImNrMnM0NjJ1dzB3cHAzbXVpaXhrdGd1YjIifQ.1TmNd7MjX3AhHdXprT4Wjg';

const MAPSTYLE = "mapbox://styles/guneriboi/cl64e2bdx000e14qjra95pzns";

// Starting Camera
const LNG = 133.6568;
const LAT = -29.6810;
const ZOOM = 3;


const drawerWidth = `25%`;
const appbarHeight = '5em';

// Layout.propTypes = {
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window: PropTypes.func,
// };


function Layout(props) {
    // const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [mobileBottomNav, setMobileBottomNav] = useState()
    const map = useRef(null);
    const mapContainer = useRef(null);

    const [lng, setLng] = useState(LNG);
    const [lat, setLat] = useState(LAT);
    const [zoom, setZoom] = useState(ZOOM);
    const [timelineDisplay, setTimelineDisplay] = useState(false);

    const [project, setProject] = useState({});
    // const [isClicked, setIsClicked] = useState(false);
    const [itemClicked, setItemClicked] = useState("");
    const [satellite, setSatellite] = useState(true);

    const [sideTabKey, setSideTabKey] = useState('about');
    const navigate = useNavigate();
    const { projects } = useContext(ProjectContext);

    const [description, showDescription] = useState(false);
    const [descriptionData, setDescriptionData] = useState({});

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: MAPSTYLE,
            center: [lng, lat],
            zoom: zoom,
            minZoom: 3,
            maxZoom: 15,
        });



        map.current.addControl(new mapboxgl.NavigationControl());
        map.current.addControl(new mapboxgl.FullscreenControl());

        map.current.on('load', () => {
            map.current.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });
            map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            map.current.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    // set up the sky layer to use a color gradient
                    'sky-type': 'gradient',
                    // the sky will be lightest in the center and get darker moving radially outward
                    // this simulates the look of the sun just below the horizon
                    'sky-gradient': [
                        'interpolate',
                        ['linear'],
                        ['sky-radial-progress'],
                        0.8,
                        'rgba(135, 206, 235, 1.0)',
                        1,
                        'rgba(0,0,0,0.1)'
                    ],
                    'sky-gradient-center': [0, 0],
                    'sky-gradient-radius': 90,
                    'sky-opacity': [
                        'interpolate',
                        ['exponential', 0.1],
                        ['zoom'],
                        5,
                        0,
                        22,
                        1
                    ]
                }
            });
        });

        map.current.on('click', (e) => {
            console.log('Inside effect Clicked')
            const layersToQuery = ['CHSitesRegister', 'Projects', 'projects', 'colonialfrontiermassacres', 'FrontierWars'].filter(layer => map.current.getLayer(layer));
            var location = map.current.queryRenderedFeatures(e.point, {
                layers: layersToQuery,
            });
            
            if (location.length !== 0) {

                console.log(location[0].layer.id);
                if (location[0].layer.id.toLowerCase() === 'projects') {
                    handleCamera({
                        center: [e.lngLat.lng, e.lngLat.lat],
                        zoom: 13,
                        pitch: 60
                    })
                    setSideTabKey('welcome')

                    // props.setSite({ type: 'Project', content: location[0].properties })
                    setDescriptionData({ type: 'Project', content: location[0].properties });

                    // navigate('welcome/site')
                    // showDescription(true);

                    // const projectID = location[0].properties.place_name;
                    // console.log('projectID: ', projectID)
                    // console.log(projects)

                    // const project = projects.find((project) => {
                    //     return project.id === projectID;
                    // });
                    // console.log("find project from map:", project)

                    // if (project) {
                    //     navigate('welcome/slider' + projectID)
                    // } else {
                    //     navigate('welcome')
                    // }


                    // setCollapsed(true);
                } else if (location[0].layer.id === 'CHSitesRegister') {
                    // setItemClicked("site");
                    // setProject(location[0].properties)
                    // console.log(location[0].properties)

                    setSideTabKey('welcome')
                    console.log('CHSitesRegister')
                    // props.setSite({ type: 'CHSitesRegister', content: location[0].properties })
                    setDescriptionData({ type: 'CHSitesRegister', content: location[0].properties });

                    //navigate('welcome/site')
                    showDescription(true);
                } else if (location[0].layer.id === 'colonialfrontiermassacres') {
                    // props.setSite({ type: 'CFM', content: location[0].properties })
                    setDescriptionData({ type: 'CFM', content: location[0].properties });
                    // navigate('welcome/site')
                    // setItemClicked("cfm");
                    console.log(location[0].properties);
                    handleCamera({
                        center: [e.lngLat.lng, e.lngLat.lat],
                        zoom: 10,
                    })
                    // setProject(location[0].properties);
                    // setSideTabKey('welcome');
                    showDescription(true);
                }
                else if (location[0].layer.id === 'FrontierWars') {
                    // props.setSite({ type: 'CFM', content: location[0].properties })
                    setDescriptionData({ type: 'CFM', content: location[0].properties });

                    navigate('welcome/site')
                    // setItemClicked("cfm");
                    console.log(location[0].properties);
                    handleCamera({
                        center: [e.lngLat.lng, e.lngLat.lat],
                        zoom: 10,
                    })
                    // setProject(location[0].properties);
                    setSideTabKey('welcome');
                }
            } else {
                // setItemClicked("");
                navigate('welcome')

            }

        })


    }, [props, description, descriptionData]);



    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
            if (zoom > 6.5 && zoom < 11) {
                setTimelineDisplay(true);
            } else {
                setTimelineDisplay(false);
            }
        });
    });

    function handleCamera(camera) {
        if (!map.current) return;
        // console.log(camera);
        map.current.flyTo(camera);
    }


    function handleSlider(event, value) {
        console.log(value)
        // const filters = ['==', 'time', '1790'];
        // map.current.setFilter('maiwar', filters);
        const filters = ['==', ['get', 'Time_Slot'], value];
        map.current.setFilter('colonialfrontiermassacres', filters);

    }

    useEffect(() => {
        satellite ? map.current.setStyle(MAPSTYLE) : map.current.setStyle('mapbox://styles/guneriboi/cl57v3imy000915p862fsupba');

    }, [satellite])




    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        // if (!mobileOpen) {
        //     setMobileBottomNav(null)
        // }
    };

    const drawer = (
        <Box sx={{
            color: "white",
            //backgroundImage: 'url(' + process.env.PUBLIC_URL + "/img/VS_indigenous-identity-dark.jpg" + ')',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "rgba(0,0,0,0.9)",
            overflow: 'scroll',
            height: '100%',
            zIndex: '0'
        }}>
            <Sidebar project={project} itemClicked={itemClicked} sideTabKey={sideTabKey} setSideTabKey={setSideTabKey} />
        </Box>
    );

    // const container = window().document.body;

    return (
        <Box sx={{ display: 'flex' }}>
{/* Commented out the AppBar section
            <AppBar
                position="fixed"
                sx={{
                    width: '100%',
                    m: 0,
                    p: 0,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    top: { xs: 0, sm: 'auto' },
                    bottom: { xs: 'auto', sm: 0 },
                    //backgroundImage: 'url(' + process.env.PUBLIC_URL + '/img/VS_indigenous-identity.jpg' + ')',
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    alignItems: 'center',
                    

                }}
            >

                <Box sx={{
                    width: { xs: '70%', sm: '90%' },
                }}>
                    <ProjectSlider setProject={setProject} setItemClicked={setItemClicked} handleCamera={handleCamera} setSideTabKey={setSideTabKey} setMobileBottomNav={setMobileBottomNav} handleDrawerToggle={handleDrawerToggle} />

                </Box>


            </AppBar> 
*/}
            <Box
                component="main"
                sx={{
                    position: 'fixed',
                    top: { xs: 'auto', sm: 0 },
                    bottom: { xs: 0, sm: 'auto' },
                    left: 0,
                    p: 0,
                    m: 0,
                    width: { xs: '100vw', sm: `calc(100% - ${drawerWidth})` },
                    height: { xs: `calc(100%)`, xl: `calc(100%)` },
                }}
                ref={mapContainer}
            >
                <Box sx={{
                    backgroundColor: "rgba(35, 55, 75, 0.9)",
                    color: "#fff",
                    padding: "6px 12px",
                    fontFamily: "monospace",
                    zIndex: 1,
                    position: "absolute",
                    bottom: "3em",
                    right: "1em",
                    borderRadius: "4px",
                    display: { xs: 'none', sm: 'block' },
                }}>
                    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}

                </Box>

                {timelineDisplay ? (<Timeline handleSlider={handleSlider} />) : (<div></div>)}

                <Box
                    sx={{
                        position: "absolute",
                        top: { xs: '1em', sm: 'auto' },
                        bottom: { xs: 'auto', sm: "3em" },
                        zIndex: 1,
                        left: "1em",
                        color: 'white'

                    }}>
                    <FormGroup >
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Satellite</Typography>
                            <Switch onChange={e => setSatellite(!e.target.checked)} size="small" />
                            <Typography>Cultural</Typography>
                        </Stack>
                    </FormGroup>

                </Box>

                <Box sx={{
                    position: "absolute",
                    top: { xs: '4em', sm: '1em' },
                    zIndex: '1',
                    left: "1em"
                }}>
                    <VSLogo handleCamera={handleCamera} lng={LNG} lat={LAT} zoom={ZOOM} setSideTabKey={setSideTabKey} />

                </Box>



            </Box>

            <Box sx={{
                width: '100%',
                position: "absolute",
                bottom: 0,
                display: { xs: 'block', sm: 'none' },

            }}>

                <MobileBottomNav mobileBottomNav={mobileBottomNav} setMobileBottomNav={setMobileBottomNav} handleDrawerToggle={handleDrawerToggle} />

            </Box>





            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    // container={container}
                    variant="temporary"
                    anchor="bottom"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', height: '40%' },
                    }}
                >
                    <Box sx={{
                        color: "white",
                        //backgroundImage: 'url(' + process.env.PUBLIC_URL + "/img/VS_indigenous-identity-dark.jpg" + ')',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "rgba(0,0,0,0.9)",
                        overflow: 'scroll',
                        height: '100%',
                        padding: '10%',
                        textAlign: 'left',

                    }}>
                        <MobileDrawerContent mobileBottomNav={mobileBottomNav} />
                    </Box>
                </Drawer>

                <Drawer
                    variant="permanent"
                    anchor="right"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        p: 0,
                        m: 0,
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: drawerWidth,
                            borderLeft: 0,
                        },

                    }}
                    open
                >
                    {drawer}
                    
                </Drawer>
            </Box>

            <DescriptionModal show={description} setShow={showDescription} site={descriptionData}/>


        </Box>
    );
}



export default Layout;