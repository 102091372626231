import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { ProjectContext } from '../context/projects';
import CFMIntro from '../modal/CFMIntro';
import { div } from 'three/examples/jsm/nodes/Nodes.js';

function DescriptionModal(props) {
    const DEFAULT_VIDEO = process.env.PUBLIC_URL + '/video/River_Bass_Loop.mp4';
    const S3BUKCET = 'https://dblk66x9sfys0.cloudfront.net/public/';
    const [displayTitle, setDisplayTitle] = useState();
    const [displayHTML, setDisplayHTML] = useState();
    const [cfmShow, setCfmShow] = useState(false);
    const { projects } = useContext(ProjectContext);

    const cfmStyle = {
        textAlign: 'center'
    }

    const siteNarrativeStyle = {
        textAlign: 'justify'
    }

    const dateStyle = {
        fontWeight: 'bold'
    }

    useEffect(() => {

        if (props.site?.type === "CHSitesRegister") {
            const siteContent = props.site.content;
            const videoName = siteContent.video + ".mp4";

            const siteTitle = (
                <span>{siteContent.heritageType}</span>
            )
            setDisplayTitle(siteTitle)

            const siteHTML = (
                <div>
                    <video autoPlay loop muted
                        src={S3BUKCET + videoName} onError={(e) => { e.target.onerror = null; e.target.src = DEFAULT_VIDEO }}
                        type="video/mp4" alt={siteContent.heritageType} />
                    <p>{siteContent.description}</p>
                </div>
            )
            setDisplayHTML(siteHTML)

        } else if (props.site?.type === "CFM") {
            const siteContent = props.site.content;
            const videoName = siteContent.video + ".mp4";

            const siteTitle = (
                <span>{siteContent.Site_Name || siteContent.heritageType}</span>
            )
            setDisplayTitle(siteTitle)

            const siteHTML = (
                <div>
                    {siteContent.Date_End && (
                        <div style={dateStyle} class="py-2">
                            {siteContent.Date_End}
                        </div>
                    )}

                    {siteContent.video && (
                        <div>
                            <video autoPlay loop muted
                                src={S3BUKCET + videoName} onError={(e) => { e.target.onerror = null; e.target.src = DEFAULT_VIDEO }}
                                type="video/mp4" alt={siteContent.heritageType} />
                        </div>
                    )}

                    <div style={siteNarrativeStyle}>
                        {siteContent.Narrative || siteContent.description}
                    </div>

                    {siteContent.Narrative && (
                    <div style={cfmStyle}>
                        <Button variant="link" onClick={() => setCfmShow(true)}>About Colonial Frontiers Massacre Map</Button>
                        <CFMIntro show={cfmShow} setShow={setCfmShow} />
                    </div>
                    )}
                </div>
            )
            setDisplayHTML(siteHTML)

        } else if (props.site?.type === "Project") {
            const siteContent = props.site.content

            const projectID = siteContent.place_name;
            console.log('projectID: ', projectID)
            console.log(projects)

            const project = projects.find((project) => {
                return project.id === projectID;
            });
            console.log("find project from map:", project)
        }

    }, [props.site, cfmShow])

    return (
        <div>
            <Modal size="lg" show={props.show} onHide={() => props.setShow(false)} className="blurred modal">
            <Modal.Header closeButton>
                    <Modal.Title>{displayTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {displayHTML}
                </Modal.Body>
            </Modal>

        </div >
    );
}

export default DescriptionModal;