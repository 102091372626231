import React, { useState, useEffect } from 'react';
import Privacy from '../../modal/Privacy';
import Contact from '../../modal/Contact';
// import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Sponsors from './Sponsors';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';



function About({ onModalStateChange }) {
    const [privacy, setPrivacy] = useState(false)
    const [contact, setContact] = useState(false)
    const [sponsors, setSponsors] = useState(false)
    useEffect(() => {
        onModalStateChange(sponsors || contact || privacy);
    }, [sponsors, contact, privacy, onModalStateChange]);

    const handleOpenSponsors = () => {
        setContact(false);
        setSponsors(true);
        setPrivacy(false);
    };

    const handleOpenContact = () => {
        setSponsors(false);
        setContact(true);
        setPrivacy(false);
    };

    const handleOpenPrivacy = () => {
        setSponsors(false);
        setContact(false);
        setPrivacy(true);
    };

    return (
        <div>
            <div className="pb-3">
                <a href="https://www.virtualsonglines.org/">
                    <img style={bilbieLogo} src={process.env.PUBLIC_URL + '/BilbieLabLogo.png'} alt='BilbieLogo' />
                </a>
            </div>

            <div>
                {/* <p>
                    Bilbie Labs designs and develops virtual heritage digital twins related to Aboriginal Australia.
                </p>

                <p>
                    We are a digital design lab and social enterprise working with First Nations communities to co-create virtual heritage landscapes.
                </p>

                <p>
                    We focus on the historical and virtual First Nations landscape where capital cities and regional towns now exist. with the purpose of engendering respect, recognition and understanding and instilling a sense of unity and belonging between mainstream and First Nations people. Essentially to engender a greater understanding of the past and the actions of European settlers, we believe we set our nation on a better track as a more inclusive society.
                </p>

                <p>
                    As a social enterprise, we create connections to country for tribal lands, showcasing cultural activities, places, significant sites, tracks, hunting grounds, gathering places and campsites. By knowing these spaces, where city buildings now stand, we seek to respect our cultural connection and share a bond of belonging that First Nations people have had since time immemorial - our work dispels the myths about our people as a 'dying race' - not worthy of citizenship or humanity - contributing evidence to this debate about a new nation that recognises our ancestral connection to the lands and waters of Australia.
                </p> */}



                <p><strong>
                Welcome to INDIJIVERSE
                </strong>
                </p>
                
                <p>
                <strong>
                    
                Journey through Virtual Songlines

                </strong>
                </p>

                <p>
                At INDIJIVERSE, a pioneering project by Bilbie XR Labs, we invite you to explore the rich tapestry of Indigenous culture and heritage across Australia. Our interactive platform weaves together traditional knowledge, local languages, the original campsites, sacred sites, and ancestral hunting and gathering grounds, all experienced through a virtual portal to hear and witness Dreamtime stories and landscapes that shaped the cultural psyche of this land.
                </p>


                <p><strong>
                A Digital Bridge to the Past
                </strong>
                </p>
                <p>
                Through immersive 3D mapping and cutting-edge technology, we are building digital twins of historically significant sites where Australia's major cities and regional towns now stand. Step back in time and walk alongside the First Nations custodians who have cared for this land since time immemorial.
                </p>
                <p>
                <strong>Honouring Tradition, Inspiring Connection</strong>
                </p>

                <p>
                We celebrate the resilience and wisdom of Aboriginal and Torres Strait Islander peoples, acknowledging their deep spiritual connection to Country. Through each cultural placemaking projects, we hope to foster greater understanding, respect, and recognition of First Nations culture and heritage across Australia.
                </p>

                <p><strong>
                Join us as we embark on a virtual journey of discovery, connecting the past with the present and inspiring a shared future.</strong>
                </p>

            </div>

            <Stack spacing={2} justifyContent="center" alignItems="center">
                <Stack spacing={2} direction="row" justifyContent="space-evenly" alignItems="center">
                    <Button variant="contained" onClick={handleOpenSponsors}>Sponsors</Button>
                    <Sponsors show={sponsors} setShow={setSponsors} />

                    <Button variant="contained" onClick={handleOpenContact}>Contact</Button>
                    <Contact show={contact} setShow={setContact} />
                </Stack>

                <div>
                    <Button variant="contained" onClick={handleOpenPrivacy}>Privacy Policy</Button>
                    <Privacy show={privacy} setShow={setPrivacy} />
                </div>

                <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                    <IconButton href="https://www.facebook.com/virtualsonglines/" target="_blank" style={socialMediaIcon}>
                        <FacebookIcon fontSize="large" />
                    </IconButton>

                    <IconButton href="https://www.linkedin.com/in/brett-leavy-23609526/" target="_blank" style={socialMediaIcon}>
                        <LinkedInIcon fontSize="large" />
                    </IconButton>

                    <IconButton href="https://twitter.com/Guneriboi" target="_blank" style={socialMediaIcon}>
                        <TwitterIcon fontSize="large" />
                    </IconButton>

                    <IconButton href="https://www.instagram.com/virtualsonglines/" target="_blank" style={socialMediaIcon}>
                        <InstagramIcon fontSize="large" />
                    </IconButton>
                </Stack>
            </Stack>

        </div>
    );
}

const bilbieLogo = {
    "width": "4em",
    "float": "left",
    "marginRight": "2em"
}

const socialMediaIcon = {
    "color": "white"
}

export default About;