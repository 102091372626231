import React from 'react'
import About from '../home/Sidebar/About';
import Sponsors from '../home/Sidebar/Sponsors';
import Welcome from '../home/Sidebar/Welcome';

export default function MobileDrawerContent(props) {


    // console.log(props.mobileBottomNav)


    if (props.mobileBottomNav === 0) {
        return (
            <Welcome />
        )
    } else if (props.mobileBottomNav === 1) {
        return (
            //<About />
            <Welcome />
        )

    } else if (props.mobileBottomNav === 2) {
        return (
            <Sponsors />
        )
    }

  return (
    <div></div>
  )
}
