import React, { useEffect, useState } from 'react';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import { createHeritageSite } from '../../graphql/mutations';
import { v4 as uuid } from 'uuid';

import { Form, Button, Container, Row } from 'react-bootstrap';

function SiteForm(props) {

    const [siteData, setSiteData] = useState({});
    const [videoData, setVideoData] = useState();
    const [videoPath, setVideoPath] = useState();


    const [postOwnerId, setPostOwnerId] = useState("")
    const [postOwnerUsername, setPostOwnerUsername] = useState("")
    // const [heritageType, setHeritageType] = useState("")
    // const [description, setDescription] = useState("")
    // const [video, setVideo] = useState("")
    // const [videoFile, setVideoFile] = useState("")
    // const [videoUploaded, setVideoUploaded] = useState(false)
    // const [heritageFieldOfficer, setHeritageFieldOfficer] = useState("")
    // const [technicalAdvisor, setTechnicalAdvisor] = useState("")
    // const [coordinator, setCoordinator] = useState("")

    useEffect(() => {

        const fetchUser = async () => {
            await Auth.currentUserInfo()
                .then(user => {
                    setPostOwnerId(user.attributes.sub);
                    setPostOwnerUsername(user.username);
                    console.log("Curr: User: ", user.username);
                    console.log("Attr.Sub: User: ", user.attributes.sub);
                })
        }
        fetchUser().catch(console.error);
    }, [])

    const uploadSite = async (e) => {
        e.preventDefault()

        if (videoData !== null && videoData !== undefined) {
            const videoType = videoData.type.split('/')[1]
            const videoName = uuid() + '.' + videoType

            const { key } = await Storage.put(videoName, videoData, {
                contentType: videoType,
            });

            setVideoPath(key)
        }


        const { heritageType, description, heritageFieldOfficer, technicalAdvisor, coordinator } = siteData;

        const createSiteInput = {
            id: uuid(),
            postOwnerId: postOwnerId,
            postOwnerUsername: postOwnerUsername,
            heritageType,
            latitude: props.lat,
            longitude: props.lng,
            location: props.project,
            description,
            video: videoPath,
            heritageFieldOfficer,
            technicalAdvisor,
            coordinator
        }

        console.log("input", createSiteInput)

        const result = await API.graphql(graphqlOperation(createHeritageSite, { input: createSiteInput }))

        console.log('site data', result);


    }

    // const handleVideoUpload = async e => {
    //     console.log(e.target.files)

    //     const videoType = e.target.files[0].type.split('/')[1]
    //     const videoName = uuid() + '.' + videoType
    //     // console.log("upload:", e.target.value)

    //     // this.setState({
    //     //     videoValue: e.target.value,
    //     // });

    //     // setVideoFile(e.target.value);


    //     const { key } = await Storage.put(videoName, e.target.files[0], {
    //         contentType: e.target.files[0].type,
    //     });

    //     // setVideo(key)
    //     // setVideoUploaded(true)

    //     // console.log('video uploaded', videoFile);

    // }



    return (
        <Container className="mt-3 mb-4">
            <Row>
                <h3>{props.project}</h3>
            </Row>
            <Form onSubmit={uploadSite}>
                <Form.Group className="mb-3">
                    <Form.Label>Heritage Type</Form.Label>
                    <Form.Select aria-label="Heritage Type"
                        value={siteData.heritageType}
                        onChange={e => setSiteData({ ...siteData, heritageType: e.target.value })}>
                        <option>---</option>
                        <option value="Crossing">River Crossing</option>
                        <option value="Confilct">Frontier Confilct</option>
                        <option value="Hunting">Hunting</option>
                        <option value="Gathering">Gathering</option>
                        <option value="Fish">Fishing</option>
                        <option value="Bora">Bora</option>
                        <option value="Camp">Camp</option>
                        <option value="Track">Track and Trade Route</option>
                        <option value="Burial">Burial</option>
                    </Form.Select>

                    {/* <Row>
                        Longitude: {props.lng} | Latitude: {props.lat}
                    </Row> */}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Longitude: {props.lng} | Latitude: {props.lat}</Form.Label>
                </Form.Group>


                <Form.Group className="mb-3" controlId="SiteDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={8} value={siteData.description}
                        onChange={e => setSiteData({ ...siteData, description: e.target.value })} />
                </Form.Group>

                <Form.Group controlId="Video" className="mb-3">
                    <Form.Label>Choose video to upload</Form.Label>
                    <Form.Control type="file" accept="video/*"
                        onChange={e => setVideoData(e.target.files[0])} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="heritageFieldOfficer">
                    <Form.Label>Heritage Field Officer</Form.Label>
                    <Form.Control type="text" placeholder="Heritage Field Officer" value={siteData.heritageFieldOfficer}
                        onChange={e => setSiteData({ ...siteData, heritageFieldOfficer: e.target.value })} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="technicalAdvisor">
                    <Form.Label>Technical Advisor</Form.Label>
                    <Form.Control type="text" placeholder="Technical Advisor" value={siteData.technicalAdvisor}
                        onChange={e => setSiteData({ ...siteData, technicalAdvisor: e.target.value })} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="coordinator">
                    <Form.Label>Coordinator</Form.Label>
                    <Form.Control type="text" placeholder="Coordinator" value={siteData.coordinator}
                        onChange={e => setSiteData({ ...siteData, coordinator: e.target.value })} />
                </Form.Group>


                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>

        </Container>
    );
}

export default SiteForm;