import React from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';


function WelcomeDefault(props) {
    return (
        <div>
            <Container fluid className='p-0'>
                <Row xs={3}>
                    <Col className='d-flex align-items-center'>
                        <img style={logo} src={process.env.PUBLIC_URL + '/Virtual-Songlines-w.png'} alt='BilbieLogo' />

                    </Col>

                    <Col className='d-flex align-items-center'>
                        <img style={logo} src={process.env.PUBLIC_URL + '/BilbieLabLogo.png'} alt='BilbieLogo' />

                    </Col>

                    {/* <Col className='d-flex align-items-center'>
                        <img style={logo} src={process.env.PUBLIC_URL + '/Virtual-Songlines-w.png'} alt='BilbieLogo' />

                    </Col> */}

                </Row>

                <Row>
                    <p>
                        We value the courage, unity, design excellence and resilience of our people and acknowledge the traditional owners of this land.
                    </p>
                    <p>
                        We value the courage the elders, past present and emerging.
                    </p>
                    <p>
                        We honour our people striving, through our work and play, facilitating the greater understanding, respect and recognition of our traditional lands, knowledge & cultural connection.
                    </p>

                </Row>
            </Container>
            {/* <div className="pb-3">
                <a href="https://www.virtualsonglines.org/">
                    <img style={vsLogo} src={process.env.PUBLIC_URL + '/Virtual-Songlines-w.png'} alt='BilbieLogo' />
                </a>
            </div> */}


            {/* <p>
                INDIJIVERSE is a virtual heritage portal transporting users back in time.
            </p>

            <p>
                This portal preserves, conserves and represents the cultural heritage of First Nations.
            </p>

            <p>
                The Indijivere is a cultural heritage 'digital twin' created by Bilbie XR Labs as a suite of interactive and immersive projects that align with Australia's capital cities and regional towns.
            </p> */}


        </div>
    );
}

const logo = {
    "width": "80%",
    // "marginBottom": "2em",
}

export default WelcomeDefault;