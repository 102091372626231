/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://mdhzxwkqqjcnhgoikqa2ixh57y.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6mj53f7tkvdgzczdu6fsjexus4",
    "aws_cognito_identity_pool_id": "ap-southeast-2:8546e3b3-d4b1-42ab-84ff-3266c99a44e9",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_bhvq9k7Rx",
    "aws_user_pools_web_client_id": "567mlhp9tdan9tl7j078dtk8s3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "virtualsonglinesdf58b-develop",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
