import React from 'react'
import { Modal } from 'react-bootstrap';


export default function SystemRequirements(props) {
  return (
    <div>
            <Modal size="lg" show={props.show} onHide={() => props.setShow(false)} className="bg-dark">
                <Modal.Header closeButton>
                    <Modal.Title>Minimum System Requirements</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4>
                    OS
                    </h4>
                    <p>
                    Windows 10 64-bit
                    </p>

                    <h4>
                    Processor
                    </h4>
                    <p>
                    Intel i5 4th generation, AMD FX 6300 or equivalent
                    </p>

                    <h4>
                    Memory
                    </h4>
                    <p>
                    8 GB RAM
                    </p>

                    <h4>
                    Storage
                    </h4>
                    <p>
                    20 GB available space
                    </p>

                    <h4>
                    Direct X

                    </h4>
                    <p>
                    Version 11
                    </p>

                    <h4>
                    Graphics
                    </h4>
                    <p>
                    NVIDIA GeForce GTX 750TI or equivalent
                    </p>

                    <h4>
                    Additional Notes

                    </h4>

                    <ul>
                        <li>Can run at 720p/60fps; </li>
                        <li>We advise you install the game on an SSD for a more streamlined experience.</li>
                    </ul>

                </Modal.Body>
            </Modal>

        </div >
  )
}
