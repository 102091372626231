//@ts-check
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
// import { Paper, Button, TextField } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import AuthHeader from '../header/AuthHeader';
import AuthFooter from '../footer/AuthFooter';

// import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings } from 'aws-amplify-react';
// import '@aws-amplify/ui-react/styles.css';
// import { Authenticator } from '@aws-amplify/ui-react';


const Signin = ({ onSignin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const signIn = async (event) => {
        try {
            event.preventDefault();
            // const data = new FormData(event.currentTarget);
            await Auth.signIn(username, password);
            navigate('/');
            onSignin();
        } catch (error) {
            console.log('error signing in', error);
        }
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     // eslint-disable-next-line no-console
    //     console.log({
    //         email: data.get('userName'),
    //         password: data.get('password'),
    //     });
    // };

    return (
        <div style={background} className="position-fixed">
            <AuthHeader />

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* <Avatar sx={{ m: 1 }}>
                        <img src={process.env.PUBLIC_URL + '/logo.svg'} alt='vs-logo' />
                    </Avatar> */}
                    {/* <Typography component="h1" variant="h5">
                        Sign in
                    </Typography> */}
                    <Box component="form" onSubmit={signIn} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="Username"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                            sx={{ p: 1 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                {/* <Link href="#" variant="body2">
                                Forgot password?
                            </Link> */}
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
            <AuthFooter />

        </div>


    );
};

const background = {
    // "color": "white",
    // position: "fixed",
    top: "0",
    bottom: "5em",
    width: "100%",
    backgroundImage: "url('./img/VS_indigenous-identity.jpg')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#cccccc",
    // "background": "rgba(208, 198, 175,1)",
}


export default Signin;



