import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectContext } from "../context/projects";
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import ProjectHeader from "../header/ProjectHeader";


import Card from 'react-bootstrap/Card';
import CheckoutModal from "../payment/CheckoutModal";

import TermsConditions from "../modal/TermsConditions";
import SystemRequirements from "../modal/SystemRequirements";


const S3BUKCET = 'https://dblk66x9sfys0.cloudfront.net/public/';

function ProjectDetails(props) {
    const { id } = useParams();
    const { projects } = useContext(ProjectContext);
    const [checkoutShow, setCheckoutShow] = useState(false);
    const [terms, setTerms] = useState(false);
    const [system, setSystem] = useState(false);

    const project = projects.find((project) => {
        return project.id === id;
    });
    if (!project) {
        return <h3>Loading...</h3>;
    }

    return (
        <div>
            <Container className="p-0" fluid>
                <ProjectHeader title={project.title} />
                <Row className="mb-4">

                    <Col>
                        <Container className="w-75 p-2">
                            <Row>
                                <h3><b>{project.title}: Subscription</b></h3>
                            </Row>
                            <Row>
                                <p><small>{project.description}</small></p>
                            </Row>

                            <Row className="mt-3 mb-1">
                                <Card bg='secondary' text='white'>
                                    <Card.Body>
                                        <Card.Title>AUD {project.price}</Card.Title>

                                        <Card.Text>
                                            Includes access to {project.title}!
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Row>
                            <Row>
                                <Button onClick={() => setCheckoutShow(true)}>
                                    Buy Now
                                </Button>
                                <CheckoutModal show={checkoutShow}
                                    onHide={() => setCheckoutShow(false)} price={project.price} cartItem={id} />
                            </Row>
                            <Row>
                                <ul>
                                    <li>
                                        <small>
                                            <Button variant="text" onClick={() => setTerms(true)}>Terms and Conditions</Button>
                                            <TermsConditions show={terms} setShow={setTerms} />
                                        </small>
                                    </li>
                                    <li>
                                        <small>
                                            <Button variant="text" onClick={() => setSystem(true)}>System Requirements</Button>
                                            <SystemRequirements show={system} setShow={setSystem} />
                                        </small>
                                    </li>
                                </ul>
                            </Row>
                        </Container>


                    </Col>
                    <Col sm={6} xxl={8}>
                        <div className="w-100">
                            <Carousel>
                                {
                                    project.screenshots.map((screenshot, idx) => (

                                        <Carousel.Item key={idx} className="w-100">
                                            <img
                                                className="d-block w-100"
                                                src={S3BUKCET + screenshot}
                                                alt="Screenshot"
                                            />
                                        </Carousel.Item>
                                    ))
                                }

                            </Carousel>
                        </div>

                    </Col>
                </Row>


            </Container>

        </div>
    );
}


export default ProjectDetails;