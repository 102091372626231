/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user
      date
      total
      projects {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        date
        total
        projects {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      title
      image
      video
      video360
      aocVideo
      gameLink
      description
      longitude
      latitude
      zoom
      pitch
      price
      map
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
query listProjects {
  listProjects {
    items {
      title
      aocVideo
      createdAt
      description
      gameLink
      image
      screenshots
      latitude
      id
      longitude
      pitch
      price
      video
      video360
      zoom
      map
    }
  }
}

`;
export const getHeritageSite = /* GraphQL */ `
  query GetHeritageSite($id: ID!) {
    getHeritageSite(id: $id) {
      id
      postOwnerId
      postOwnerUsername
      heritageType
      latitude
      longitude
      location
      description
      video
      videoDescription
      images
      imagesDescription
      visibility
      siteIssue
      identifiedStatus
      comment
      clearedToProceed
      heritageFieldOfficer
      technicalAdvisor
      coordinator
      createdAt
      updatedAt
    }
  }
`;
export const listHeritageSites = /* GraphQL */ `
  query ListHeritageSites(
    $filter: ModelHeritageSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeritageSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postOwnerId
        postOwnerUsername
        heritageType
        latitude
        longitude
        location
        description
        video
        videoDescription
        images
        imagesDescription
        visibility
        siteIssue
        identifiedStatus
        comment
        clearedToProceed
        heritageFieldOfficer
        technicalAdvisor
        coordinator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
