import React from 'react';
import { Slider } from '@mui/material';

// Frontier War timeline

function Timeline(props) {

    const marks = [
        {
            value: 1780,
            label: '1780s',
        },
        {
            value: 1790,
            label: '1790s',
        },
        {
            value: 1800,
            label: '1800s',
        },
        {
            value: 1810,
            label: '1810s',
        },
        {
            value: 1820,
            label: '1820s',
        },
        {
            value: 1830,
            label: '1830s',
        },
        {
            value: 1840,
            label: '1840s',
        },
        {
            value: 1850,
            label: '1850s',
        },
        {
            value: 1860,
            label: '1860s',
        },
        {
            value: 1870,
            label: '1870s',
        },
        {
            value: 1880,
            label: '1880s',
        },
        {
            value: 1890,
            label: '1890s',
        },
        {
            value: 1900,
            label: '1900s',
        },
        {
            value: 1910,
            label: '1910s',
        },
        // {
        //     value: 1920,
        //     label: '1920s',
        // },
        // {
        //     value: 1930,
        //     label: '1930s',
        // },
    ];

    function valuetext(value) {
        return `${value}`;
    }


    return (
        <div style={timelineStyle}>
            <Slider
                aria-label="timeline"
                defaultValue={1780}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                marks={marks}
                step={null}
                min={1780}
                max={1910}
                onChangeCommitted={props.handleSlider}
            />

        </div>
    );
}

const timelineStyle = {
    position: 'absolute',
    width: '60%',
    bottom: '6em',
    right: '1em',
    padding: '10px',
    // backgroundColor: 'white',
    textAlign: 'center',
    zIndex: '1'
}

export default Timeline;