import React from 'react';


function AuthHeader(props) {

    return (
        <div className='d-flex justify-content-between bg-dark text-white p-3 mb-3'>
            <div style={logoStyle}>
                <a href="/">
                    <img src={process.env.PUBLIC_URL + '/Virtual-Songlines-w.png'} alt='vsLogo' />
                </a>
            </div>
            {/* <div>
                <h3>{props.title}</h3>
            </div> */}
            {/* <Container className='bg-dark text-white'>
                <Row className='d-flex justify-content-between'>
                    <Col sm={2}>
                        <img src='./Virtual-Songlines.png' alt='vsLogo' />

                    </Col>

                    <Col>
                        <h3>{props.title}</h3>

                    </Col>
                </Row>
            </Container> */}

        </div>
    );
}

const logoStyle = {
    "width": "3em",
}

export default AuthHeader;