import React, { useContext } from 'react';
// import Header from '../header/Header';
import Footer from '../footer/Footer';
import { ProjectContext } from '../context/projects';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';


function Projects(props) {
    const { projects } = useContext(ProjectContext);

    return (
        <div>
            {/* <Header /> */}
            <Container className="p-5 mb-5" fluid>
                <Row>
                    <h3>Projects</h3>
                </Row>
                <Row xs={1} md={5}>
                    {
                        projects.map((project, idx) => (
                            <Col key={idx} className="mb-4">
                                <Card>
                                    <Card.Img variant="top" src={project.imgAccessURL} alt={project.image} />
                                    <Card.Body>
                                        <Button variant="dark" href={"/projects/" + project.id}>{project.title}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
            <div style={footerStyle} className="bg-white">
                <Footer loggedIn={props.loggedIn} signOut={props.signOut} />
            </div>

        </div>
    );
}

const footerStyle = {
    height: "5em",
    position: "fixed",
    bottom: "0",
}


export default Projects;