import React, { useState } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import About from './About';
import { useNavigate } from "react-router-dom";

import Sponsors from './Sponsors';
import Welcome from './Welcome';

import './SidebarButton.css';


function Sidebar(props) {

    // const [key, setKey] = useState('welcome');

    // const [mapShow, setMapShow] = useState(false);
    // const [displayHTML, setDisplayHTML] = useState();
    // const [qr, setQr] = useState('');
    // const [video360AccessURL, setVideo360AccessURL] = useState('https://vs360maiwar.s3.ap-southeast-2.amazonaws.com/360/albert.html');

    // const heritageSiteNames = [['campCheck', 'Camp'], ['burialCheck', 'Burial'], ['boraCheck', 'Bora'], ['loreCheck', 'Lore'], ['crossingCheck', 'River Crossing'], ['medicineCheck', 'Bush Medicine'], ['foodCheck', 'Bush Food'], ['huntingCheck', 'Hunting'], ['fishingCheck', 'Fishing'], ['middenCheck', 'Midden']]

    // const heritageSiteNames = [['OccupationCheck', 'Occupation'], ['CeremonialCheck', 'Ceremonial places'], ['GatheringCheck', 'Gathering'], ['ScarredCheck', 'Scarred or carved trees'], ['FishingCheck', 'Fish traps and weirs'], ['QuarriesAndArtefactScattersCheck', 'Quarries and artefact scatters'], ['GrindingGroovesCheck', 'Grinding grooves'], ['WellsCheck', 'Wells'], ['CrossingCheck', 'River Crossing'], ['middenCheck', 'Midden'], ['RockArtCheck', 'Rock art'], ['BurialsCheck', 'Burials'], ['ContactSitesCheck', 'Contact']]

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalStateChange = (isOpen) => {
        setIsModalOpen(isOpen);
    };

    const contentStyle = {
        padding: '5% 10%',
        textAlign: 'left',
        opacity: isModalOpen ? '0.1' : '1',
        zIndex:'0'
    }

    return (
        <div>

            <div style={contentStyle}>
                <About onModalStateChange={handleModalStateChange}/>
            </div>


        </div>
    );
}

export default Sidebar;