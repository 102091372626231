import React from 'react';
import Sponsors from './sponsors.json';
import { Button } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';


function Footer(props) {

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };


    return (
        <div style={footerSyle}>
            <Container className="bg-white">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Button href="/">Home</Button>
                            </Col>
                            <Col>
                                <div>

                                    {props.loggedIn ?
                                        (
                                            <Button onClick={props.signOut}>Logout</Button>
                                        ) : (

                                            <Button href="signin">Login</Button>
                                        )}

                                    {/* <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        Dashboard
                                    </Button>

                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        {props.loggedIn ?
                                            (
                                                <div>
                                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                                    <MenuItem onClick={props.signOut}>Logout</MenuItem>
                                                </div>
                                            ) : (
                                                <div>
                                                    <MenuItem onClick={handleClose}><Link to="/signin">Login</Link></MenuItem>
                                                </div>
                                            )}

                                    </Menu> */}
                                </div>


                            </Col>
                            {/* <Col>
                                <Button href="/projects">Projects</Button>
                            </Col> */}

                        </Row>
                        <Row>
                            <p style={copyrightStyle}>©2022 by Bilbie Virtual Labs. Virtual Songlines R and its related logo are registered trademarks of the Bilbie Virtual Labs. All Rights Reserved</p>


                        </Row>
                    </Col>

                    <Col className="d-flex align-items-center">
                        <div style={rightStyle}>
                            {
                                Sponsors.map((sponsor, idx) => (
                                    <a key={idx} href={sponsor.link} target="_blank" rel="noreferrer"><img style={supportLogo} src={process.env.PUBLIC_URL + '/support/' + sponsor.logo} alt={sponsor.title} /></a>
                                ))
                            }

                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const footerSyle = {
    width: "100vw"
}

const copyrightStyle = {
    fontSize: '0.6rem',
}

const rightStyle = {
    display: 'flex',
}

const supportLogo = {
    height: '1.5em',
    width: 'auto',
    margin: '0 0.5rem',
}



export default Footer;