import React from 'react';
import { Outlet } from "react-router-dom";


function Welcome(props) {
    return (
        <div>
            <Outlet />
        </div>

    );
}



export default Welcome;