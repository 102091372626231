import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import { ProjectContext } from "../context/projects";
import { Container, Row, Col } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import Footer from '../footer/Footer';
import CheckoutForm from './CheckoutForm';


function Checkout(props) {
    const { id } = useParams();
    const { projects } = useContext(ProjectContext);


    const project = projects.find((project) => {
        return project.id === id;
    });
    if (!project) {
        return <h3>Loading...</h3>;
    }

    const stripePromise = loadStripe('pk_test_4fQ5aieqWlrcMXvp7Xcd4juE');

    const { image, imgAccessURL, title, price } = project;
    const cartItem = {id: id, title: title, image: image, price: price, amount: 1};



    return (
        <div>
            <Elements stripe={stripePromise}>
                <Container className="pt-4">
                    <Row>
                        <h3>Checkout</h3>
                    </Row>
                    <Row>
                        <Col>
                            <img src={imgAccessURL} alt={image} />
                        </Col>
                        <Col>
                            <Row>
                                <h3>{title}</h3>
                            </Row>
                            <Row>
                                <p>Price: {price} AUD</p>
                            </Row>
                            <Row>
                                <CheckoutForm total={price} cartItem={cartItem}/>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Footer loggedIn={props.loggedIn} signOut={props.signOut} />
            </Elements>


        </div>
    );
}

export default withAuthenticator(Checkout, {
    includeGreetings: true,
});