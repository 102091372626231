import React, { useContext } from 'react';
import { CartContext } from "../context/cart";
import { Container, Row} from 'react-bootstrap';


function Cart(props) {
    const { cart, total } = useContext(CartContext);
    console.log(cart)

    if (!cart.length) {
        return <h3>Empty Cart</h3>
    }

    return (
        <div>
            <Container>
                <Row>
                    <h3>My Cart</h3>
                </Row>
                <Row>
                    {cart.map(({ id, title, price, image }) => (
                        <article key={id} className="cart-item">
                            <div className="image">
                                <img src={image} alt="cart item" />
                            </div>
                            <div className="details">
                                <p>{title}</p>
                                <p>$ {price}</p>
                            </div>
                        </article>
                    ))}
                </Row>
                <Row>
                    <h3>Total: $ {total}</h3>
                </Row>
            </Container>

        </div>
    );
}

export default Cart;