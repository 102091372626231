import React, { useState } from 'react'
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import InfoIcon from '@mui/icons-material/Info';
import SupportIcon from '@mui/icons-material/Support';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { styled } from "@mui/material/styles";

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: white;
  }
`);

const MobileBottomNav = (props) => {
    return (
        <BottomNavigation
            showLabels
            value={props.mobileBottomNav}
            onChange={(event, newValue) => {
                props.setMobileBottomNav(newValue);
                props.handleDrawerToggle()
            }}
            sx={{background: 'black'}}

        >
            <BottomNavigationAction label="Welcome" icon={<AccessibilityNewIcon />} />
            <BottomNavigationAction label="About" icon={<InfoIcon />}  />
            <BottomNavigationAction label="Sponsors" icon={<SupportIcon />} />
        </BottomNavigation>
    )
}

export default MobileBottomNav;
