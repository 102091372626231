/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processOrder = /* GraphQL */ `
  mutation ProcessOrder($input: ProcessOrderInput!) {
    processOrder(input: $input)
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      title
      image
      video
      video360
      aocVideo
      gameLink
      description
      longitude
      latitude
      zoom
      pitch
      price
      screenshots
      map
      orders {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      title
      image
      video
      video360
      aocVideo
      gameLink
      description
      longitude
      latitude
      zoom
      pitch
      price
      screenshots
      map
      orders {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      title
      image
      video
      video360
      aocVideo
      gameLink
      description
      longitude
      latitude
      zoom
      pitch
      price
      screenshots
      map
      orders {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHeritageSite = /* GraphQL */ `
  mutation CreateHeritageSite(
    $input: CreateHeritageSiteInput!
    $condition: ModelHeritageSiteConditionInput
  ) {
    createHeritageSite(input: $input, condition: $condition) {
      id
      postOwnerId
      postOwnerUsername
      heritageType
      latitude
      longitude
      location
      description
      video
      videoDescription
      images
      imagesDescription
      visibility
      siteIssue
      identifiedStatus
      comment
      clearedToProceed
      heritageFieldOfficer
      technicalAdvisor
      coordinator
      createdAt
      updatedAt
    }
  }
`;
export const updateHeritageSite = /* GraphQL */ `
  mutation UpdateHeritageSite(
    $input: UpdateHeritageSiteInput!
    $condition: ModelHeritageSiteConditionInput
  ) {
    updateHeritageSite(input: $input, condition: $condition) {
      id
      postOwnerId
      postOwnerUsername
      heritageType
      latitude
      longitude
      location
      description
      video
      videoDescription
      images
      imagesDescription
      visibility
      siteIssue
      identifiedStatus
      comment
      clearedToProceed
      heritageFieldOfficer
      technicalAdvisor
      coordinator
      createdAt
      updatedAt
    }
  }
`;
export const deleteHeritageSite = /* GraphQL */ `
  mutation DeleteHeritageSite(
    $input: DeleteHeritageSiteInput!
    $condition: ModelHeritageSiteConditionInput
  ) {
    deleteHeritageSite(input: $input, condition: $condition) {
      id
      postOwnerId
      postOwnerUsername
      heritageType
      latitude
      longitude
      location
      description
      video
      videoDescription
      images
      imagesDescription
      visibility
      siteIssue
      identifiedStatus
      comment
      clearedToProceed
      heritageFieldOfficer
      technicalAdvisor
      coordinator
      createdAt
      updatedAt
    }
  }
`;
export const createProjectOrder = /* GraphQL */ `
  mutation CreateProjectOrder(
    $input: CreateProjectOrderInput!
    $condition: ModelProjectOrderConditionInput
  ) {
    createProjectOrder(input: $input, condition: $condition) {
      id
      project_id
      order_id
      order {
        id
        user
        date
        total
        projects {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      project {
        id
        title
        image
        video
        video360
        aocVideo
        gameLink
        description
        longitude
        latitude
        zoom
        pitch
        price
        screenshots
        map
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer
    }
  }
`;
export const updateProjectOrder = /* GraphQL */ `
  mutation UpdateProjectOrder(
    $input: UpdateProjectOrderInput!
    $condition: ModelProjectOrderConditionInput
  ) {
    updateProjectOrder(input: $input, condition: $condition) {
      id
      project_id
      order_id
      order {
        id
        user
        date
        total
        projects {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      project {
        id
        title
        image
        video
        video360
        aocVideo
        gameLink
        description
        longitude
        latitude
        zoom
        pitch
        price
        screenshots
        map
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer
    }
  }
`;
export const deleteProjectOrder = /* GraphQL */ `
  mutation DeleteProjectOrder(
    $input: DeleteProjectOrderInput!
    $condition: ModelProjectOrderConditionInput
  ) {
    deleteProjectOrder(input: $input, condition: $condition) {
      id
      project_id
      order_id
      order {
        id
        user
        date
        total
        projects {
          nextToken
        }
        createdAt
        updatedAt
        customer
      }
      createdAt
      updatedAt
      project {
        id
        title
        image
        video
        video360
        aocVideo
        gameLink
        description
        longitude
        latitude
        zoom
        pitch
        price
        screenshots
        map
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      projects {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      projects {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user
      date
      total
      projects {
        items {
          id
          project_id
          order_id
          createdAt
          updatedAt
          customer
        }
        nextToken
      }
      createdAt
      updatedAt
      customer
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      createdAt
      updatedAt
    }
  }
`;
