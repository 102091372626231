import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Projects from '../projects/projects.json';

import { Container, Row, Col } from 'react-bootstrap';
import SiteForm from './SiteForm';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken =
    'pk.eyJ1IjoiZ3VuZXJpYm9pIiwiYSI6ImNrMnM0YTlmejA2Nngzbm8zYmE0b29zc2gifQ.TXwvgDocFljyHybRpSAILA';

const MAPSTYLE = "mapbox://styles/guneriboi/ckvnrld4l0cb814plmn1878cz";

var currentMarkers = [];


function EditorMap(props) {
    let { title } = useParams();
    const project = Projects.find(project => { return project.title === title })

    const map = useRef(null);
    const mapContainer = useRef(null);
    const lng = project.camera.center[0]
    const lat = project.camera.center[1]
    const zoom = project.camera.zoom

    const [lngClicked, setLngClick] = useState(0);
    const [latClicked, setLatClick] = useState(0);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: MAPSTYLE,
            center: [lng, lat],
            zoom: zoom,
            minZoom: 3,
            maxZoom: 15,
        });

        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

        map.current.getCanvas().style.cursor = 'crosshair';

    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('click', (e) => {
            setLngClick(e.lngLat.lng.toFixed(4));
            setLatClick(e.lngLat.lat.toFixed(4));

            if (currentMarkers !== null) {
                for (var i = currentMarkers.length - 1; i >= 0; i--) {
                    currentMarkers[i].remove();
                }
            }

            const marker = new mapboxgl.Marker({
                color: "#FFFFFF",
                draggable: true
            }).setLngLat(e.lngLat)
                .addTo(map.current);

            currentMarkers.push(marker);

            // getLocations();

        });

    });


    return (

        <div>
            <div>
                <Container fluid className="p-0">
                    <Row style={containerStyle}>
                        <Col>
                            <div ref={mapContainer} style={mapStyle}/>
                        </Col>

                        <Col xs lg="3" className="h-100 overflow-scroll">
                            <SiteForm lng={lngClicked} lat={latClicked} project={title} />
                        </Col>
                    </Row>

                </Container>

            </div>


        </div>
    );
}

const containerStyle = {
    "height": "100vh"
}

const mapStyle = {
    "height": "100%",
    "width": "100%"
};

export default EditorMap;