import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { ProjectContext } from "../context/projects";
// import { CartContext } from "../context/cart";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Container, Row, Button, Form } from 'react-bootstrap';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const CheckoutForm = (props) => {
  // const { cart, total, clearCart } = useContext(CartContext);
  const { checkout } = useContext(ProjectContext);
  const [orderDetails, setOrderDetails] = useState({ cart: props.cartItem, total: props.total, address: "test adress", token: null });
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  // const history = useHistory();

  useEffect(() => {
    if (orderDetails.token) {
      checkout(orderDetails);
      //clearCart();
      //history.push("/");
    }
  }, [orderDetails, checkout]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({ ...orderDetails, token: token.id });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control type="text" placeholder="Address" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
        </Form.Group>

        <Row className="mt-2 mb-4">
          <div className="checkout-form">
            <div className="stripe-section">
              {/* <PaymentElement /> */}
              <label htmlFor="stripe-element"> Credit or debit card </label>
              <CardElement id="stripe-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
              {/* <CardNumberElement/> */}
            </div>
          </div>

        </Row>

        <Row>
          <div className="card-errors" role="alert">
            {error}
          </div>

        </Row>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          {/* <Form.Check type="checkbox" label="I have read and agree to the Terms & Conditions" /> */}
          <Form.Check type="checkbox" label={(<>I have read and agree to the <a href="/terms" target='_blank'>Terms & Conditions</a></>)} />
        </Form.Group>

        <Row>
          <Button type="submit">
            Submit Payment
          </Button>

        </Row>

      </Form>

    </Container>

  );
};

export default CheckoutForm;
