import React from 'react'
import { Modal } from 'react-bootstrap';


export default function TermsConditions(props) {
    return (
        <div>
            <Modal size="lg" show={props.show} onHide={() => props.setShow(false)} className="bg-dark">
                <Modal.Header closeButton>
                    <Modal.Title>TERMS AND CONDITIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>
                        These terms and conditions (the "Terms and Conditions") govern the use of www.virtualsonglines.org (the "Site"). This Site is owned and operated by Bilbie Pty Ltd.
                    </p>

                    <p>
                        By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.

                    </p>

                    <h3>
                        Intellectual Property

                    </h3>

                    <p>
                        All content published and made available on our Site is the property of Bilbie Pty Ltd and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.

                    </p>

                    <h3>
                        Accounts

                    </h3>

                    <p>
                        When you create an account on our Site, you agree to the following:
                    </p>

                    <ol start="1">
                        <li>
                            You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and

                        </li>

                        <li>
                            All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.

                        </li>
                    </ol>

                    <p>
                        We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.

                    </p>

                    <h3>
                        Subscriptions
                    </h3>

                    <p>
                        Your subscription automatically renews and you will be automatically billed until we receive notification that you want to cancel the subscription.
                    </p>

                    <h3>
                        Payments

                    </h3>

                    <p>
                        We accept the following payment methods on our Site:

                    </p>

                    <ul>
                        <li>
                            Credit Card;
                        </li>
                        <li>
                            PayPal; and
                        </li>
                        <li>
                            Debit.
                        </li>
                    </ul>

                    <p>
                        When you provide us with your payment information, you authorise our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorise us to charge the amount due to this payment instrument.

                    </p>

                    <p>
                        If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.
                    </p>

                    <h3>
                        Consumer Protection Law

                    </h3>

                    <p>
                        Where the Australian Consumer Law, Schedule 2 of the Competition and Consumer Act 2010, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.

                    </p>

                    <h3>
                        Limitation of Liability
                    </h3>

                    <p>
                        Bilbie Pty Ltd and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.

                    </p>
                    <h3>
                        Indemnity
                    </h3>

                    <p>
                        Except where prohibited by law, by using this Site you indemnify and hold harmless Bilbie Pty Ltd and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.
                    </p>

                    <h3>
                        Applicable Law

                    </h3>

                    <p>
                        These Terms and Conditions are governed by the laws of the State of Queensland.
                    </p>

                    <h3>Severability</h3>

                    <p>
                        If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.

                    </p>

                    <h3>
                        Changes
                    </h3>

                    <p>
                        These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.
                    </p>

                    <p>
                        You can also contact us through the feedback form available on our Site.
                    </p>

                </Modal.Body>
            </Modal>

        </div >
    )
}
