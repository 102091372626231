import React, { useState, useEffect, useContext } from 'react';

// import { Button, Container, Row, Col, Card } from 'react-bootstrap';
// import QRCode from 'qrcode';

import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../context/projects";
import WelcomeDefault from './WelcomeDefault';
import Button from 'react-bootstrap/Button';

import CFMIntro from '../../modal/CFMIntro';




const DEFAULT_VIDEO = process.env.PUBLIC_URL + '/video/River_Bass_Loop.mp4';
const S3BUKCET = 'https://dblk66x9sfys0.cloudfront.net/public/';

function Site(props) {
    const [displayHTML, setDisplayHTML] = useState();
    const [cfmShow, setCfmShow] = useState(false);
    const { projects } = useContext(ProjectContext);
    const navigate = useNavigate();


    // console.log(props.site)
    // const [qr, setQr] = useState('');
    // const [video360AccessURL, setVideo360AccessURL] = useState('https://vs360maiwar.s3.ap-southeast-2.amazonaws.com/360/albert.html');


    useEffect(() => {

        if (props.site.type === "CHSitesRegister") {
            const siteContent = props.site.content
            const videoName = siteContent.video + ".mp4"
            // console.log(siteContent.video)
            // getVideoURL(videoName);

            // if (siteContentt.video360 !== null && siteContent.video360 !== undefined) {
            //     setVideo360AccessURL(video360Prefix + siteContent.video360 + ".html");
            // }

            // QRCode.toDataURL(video360AccessURL, {
            //     width: 800,
            //     margin: 2,
            //     color: {
            //         dark: '#335383FF',
            //         light: '#EEEEEEFF'
            //     }
            // }, (err, url) => {
            //     if (err) return console.error(err)

            //     console.log(url)
            //     setQr(url)
            // })

            const siteHTML = (
                <div>
                    <h3 style={tileStyle}>{siteContent.heritageType}</h3>
                    <video autoPlay loop muted
                        src={S3BUKCET + videoName} onError={(e) => { e.target.onerror = null; e.target.src = DEFAULT_VIDEO }}
                        type="video/mp4" alt={siteContent.heritageType} />
                    <p>{siteContent.description}</p>

                    {/* <img src={qr} alt="360 Video QRcode" className='mt-3' /> */}
                </div>
            )

            setDisplayHTML(siteHTML);
        } else if (props.site.type === "CFM") {

            const siteContent = props.site.content
            var cfmHTML = (
                <div>
                    {/* <h4>Colonial Frontier Massacres ID:</h4>
                    <p>{siteContent.cfm_ID}</p> */}
                    {siteContent.Site_Name !== "NULL" ? <h3 style={tileStyle}>{siteContent.Site_Name}</h3> : <div></div>}

                    {siteContent.Date_End !== "NULL" ? <p>{siteContent.Date_End}</p> : <div></div>}
                    <br />
                    {/* {siteContent.Date_Start !== "NULL" ? <p>Date: Between {siteContent.Date_Start} and {siteContent.Date_End}</p> : <div></div>} */}
                    {siteContent.Narrative !== "NULL" ? <p dangerouslySetInnerHTML={{ __html: siteContent.Narrative }}></p> : <div></div>}
                    <div>
                        <Button variant="link" onClick={() => setCfmShow(true)}>About Colonial Frontiers Massacre Map</Button>
                        <CFMIntro show={cfmShow} setShow={setCfmShow} />
                    </div>

                </div>
            )

            setDisplayHTML(cfmHTML);
        } else if (props.site.type === "Project") {
            const siteContent = props.site.content

            const projectID = siteContent.place_name;
            console.log('projectID: ', projectID)
            console.log(projects)

            const project = projects.find((project) => {
                return project.id === projectID;
            });
            console.log("find project from map:", project)

            if (project) {
                navigate(projectID)
            } else {
                setDisplayHTML(<WelcomeDefault />);
            }
        }

    }, [props.site, cfmShow])

    return (
        <div>
            {displayHTML}

        </div>
    );
}

const tileStyle = {
    fontWeight: "bold"
}

export default Site;