import React, { useEffect, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { listProjects } from "../../api/queries";
import { processOrder } from "../../api/mutations";


const ProjectContext = React.createContext();

const S3BUKCET = 'https://dblk66x9sfys0.cloudfront.net/public/';

const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function getAccessURL(projectList) {
      projectList.forEach(async (project) => {
        var thumbnailImage = 'thumbnail/thumbnail-' + project.image;
        const imgAccessURL = await Storage.get(thumbnailImage);
        const videoAccessURL = S3BUKCET + project.video;
        const video360AccessURL = S3BUKCET + project.video360;
        const screenshotsData = []
  
        for (let i = 0; i < project.screenshots.length; i++) {
          const accessURL = await Storage.get(project.screenshots[i]);
          screenshotsData.push(accessURL)
  
        }
        const newProject = {
          ...project,
          imgAccessURL: imgAccessURL,
          screenshotsAccessURL: screenshotsData,
          videoAccessURL: videoAccessURL,
          video360AccessURL: video360AccessURL
        }
        setProjects(old => [...old, newProject])
  
      })
    }
  
    const fetchProjects = async () => {
      try {
        setLoading(true);
        // Switch authMode to API_KEY for public access
        const { data } = await API.graphql({
          query: listProjects,
          authMode: "API_KEY"
        });
        const projectList = data.listProjects.items;
        getAccessURL(projectList)
        setLoading(false);
        //   console.log(projects)
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchProjects();
  }, []);

  const checkout = async (orderDetails) => {
    const payload = {
      id: uuidv4(),
      ...orderDetails
    };
    console.log(payload);
    try {
      await API.graphql(graphqlOperation(processOrder, { input: payload }));
      console.log("Order is successful");
    } catch (err) {
      console.log("error in process order")
      console.log(err);
    }
  };

  

  return (
    <ProjectContext.Provider value={{ projects, loading, checkout }}>
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectProvider };
