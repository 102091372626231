import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProjectProvider } from './components/context/projects';
import { CartProvider } from './components/context/cart';

import './style.css';

// import Amplify, { Auth } from 'aws-amplify';
// import awsconfig from './aws-exports';

import 'bootstrap/dist/css/bootstrap.min.css';
// Amplify.configure(awsconfig);

ReactDOM.render(
  <ProjectProvider>
    <CartProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </CartProvider>
  </ProjectProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
