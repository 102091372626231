import React from 'react';
import { useNavigate } from "react-router-dom";


function VSLogo(props) {

    const navigate = useNavigate();

    function resetMap() {
        props.handleCamera({
            center: [props.lng, props.lat],
            zoom: props.zoom,
            pitch: 0,
        });
        props.setSideTabKey('about');
        navigate('/');
        

    }

    return (
        <div style={logoStyle}>
            <img src={process.env.PUBLIC_URL + '/Virtual-Songlines-w.png'} alt='vsLogo' onClick={resetMap}/>
            
        </div>
    );
}

const logoStyle = {
    // "position": "absolute",
    // "top":"2em",
    // "zIndex": "1",
    "width": "4em",
    // "left": "3em",
    "color": "white",
    "textAlign": "center",
    "fontFamily": "Amatic SC",
    fontSize: "1.5em",
    fontWeight: "bold"
}

export default VSLogo;