import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
// import Projects from '../projects/projects.json';
import { ProjectContext } from '../context/projects';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

function Editor(props) {

    const { projects } = useContext(ProjectContext);
    const [editmode, setEditMode] = useState(false);

    function handleEditProject(e) {
        setEditMode(e.target.checked)
        // console.log(e.target.checked)
    }

    return (
        <div style={containerStyle}>
            <Container className="h-100 pt-4">
                <Row className='d-flex justify-content-between' xs={1} md={5}>
                    <h3>Projects</h3>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="editmode-switch"
                            label="Edit Project"
                            onChange={handleEditProject}
                        />
                    </Form>
                </Row>
                <Row xs={1} md={5}>
                    {
                        projects.map((project, idx) => (
                            <Col key={idx} className="mb-4">
                                <Card>
                                    <Card.Img variant="top" src={project.imgAccessURL} alt={project.image} />
                                    <Card.Body>
                                        {/* <Card.Title>{project.title}</Card.Title> */}
                                        {/* <Card.Link href="#disabled">{project.title}</Card.Link> */}
                                        {editmode ? 
                                        (
                                            <Button variant="primary" href={"/editor/project/" + project.id}>{project.title}</Button>
                                        ):(
                                            <Button variant="secondary" href={"/editor/" + project.title}>{project.title}</Button>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </div>

    );
}

const containerStyle = {
    "height": "100vh"
}

export default withAuthenticator(Editor, {
    includeGreetings: true,
});