import React from 'react';
import background from './VS_indigenous-identity.jpg';

function ProjectHeader(props) {

    return (
        <div style={topBannerStyle}>

            <div className='d-flex justify-content-between p-3 mb-3'>
                <div style={logoStyle}>
                    <a href="/">
                        <img src={process.env.PUBLIC_URL + '/Virtual-Songlines.png'} alt='vsLogo' />
                    </a>
                </div>
                <div>
                    <h3 className='fw-bolder'>{props.title}</h3>
                </div>
                {/* <Container className='bg-dark text-white'>
                <Row className='d-flex justify-content-between'>
                    <Col sm={2}>
                        <img src='./Virtual-Songlines.png' alt='vsLogo' />

                    </Col>

                    <Col>
                        <h3>{props.title}</h3>

                    </Col>
                </Row>
            </Container> */}

            </div>
        </div>

    );
}

const logoStyle = {
    "width": "4em",
}

const topBannerStyle = {
    // height: "5em",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // padding: "1em 2em",
}

export default ProjectHeader;