import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Auth } from 'aws-amplify';

import { useNavigate } from 'react-router-dom';

import AuthHeader from '../header/AuthHeader';
import AuthFooter from '../footer/AuthFooter';

export default function SignUp() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const [authenticationCode, setAuthenticationCode] = useState('');
    const [step, setStep] = useState(0);

    const navigate = useNavigate();

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     // eslint-disable-next-line no-console
    //     console.log({
    //         email: data.get('email'),
    //         password: data.get('password'),
    //     });
    // };

    async function signUp(event) {
        try {
            event.preventDefault();
            // const data = new FormData(event.currentTarget);
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email,
                    'custom:first-name': firstName,
                    'custom:given-name': lastName,
                }
            });
            setStep(1);
            console.log(user);
        } catch (error) {
            console.log('error signing up:', error);
        }
    }

    async function confirmSignUp(event) {
        try {
            event.preventDefault();
            await Auth.confirmSignUp(username, authenticationCode);
            console.log('user successfully comfirmed signup');
            signIn();

        } catch (error) {
            console.log('error confirm signing up:', error);
        }
    }

    async function signIn() {
        try {
            await Auth.signIn(username, password);
            navigate('/');

        } catch (error) {
            console.log('error signing in:', error);
        }
    }

    return (
        <div>
            <AuthHeader />
            <Container component="main" maxWidth="xs">
                {step === 0 && (
                    <div>
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {/* <Avatar sx={{ m: 1 }}>
                                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt='vs-logo' />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography> */}
                            <Box component="form" noValidate onSubmit={signUp} sx={{ mt: 3, mb: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="family-name"
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="username"
                                            label="Username"
                                            name="username"
                                            autoComplete="username"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            autoComplete="new-password"
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                                </Grid>
                                <Button
                                    className='mt-4'
                                    type="submit"
                                    // onClick={signUp}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/signin" variant="body2">
                                            Already have an account? Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </div>

                )}
                {step === 1 && (
                    <div>
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1 }}>
                                {/* <LockOutlinedIcon /> */}
                                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt='vs-logo' />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                We Emailed You
                            </Typography>
                            <Typography>
                                Your code is on the way. To log in, enter the code we emailed to {email}. It may take a minute to arrive.
                            </Typography>
                            <Box component="form" noValidate onSubmit={confirmSignUp} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} >
                                        <TextField
                                            required
                                            fullWidth
                                            name="authenticationCode"
                                            label="Authentication Code"
                                            id="authenticationCode"
                                            value={authenticationCode}
                                            onChange={e => setAuthenticationCode(e.target.value)}
                                        />
                                    </Grid>

                                </Grid>
                                <Button
                                    type="submit"
                                    // onClick={confirmSignUp()}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Confirm
                                </Button>

                            </Box>
                        </Box>
                    </div>

                )}


            </Container>

            <AuthFooter />
        </div>
    );
}